






































import { Component, Vue } from 'vue-property-decorator'
import IllustrationCreateFaq from '@/app/ui/assets/ill_create_faq.vue'
import Button from '@/app/ui/components/Button/index.vue'
import Card from '@/app/ui/components/Card/index.vue'
import Loading from '@/app/ui/components/Loading/index.vue'

@Component({
  components: {
    IllustrationCreateFaq,
    Button,
    Card,
    Loading,
  },
})
export default class PickupTimeManagement extends Vue {
  tabs = [
    {
      label: 'Data Pick Up Time',
      name: 'PickupTimeManagementTabPickupTime',
      disabled: false,
    },
  ]
}
